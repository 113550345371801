<template>
  <div v-if="!loadingUser">
    <NavBar />
    <div id="default-layout" class="bg-gray-100 min-h-screen relative">
      <slot />
    </div>
    <Footer />
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
import { useQuery } from "@vue/apollo-composable";
import NavBar from '@/components/Navigation/TheNavBar';
import Footer from "@/components/Navigation/TheFooter";
import { GET_CURRENCIES } from "@/graphql/currencies";

export default {
  components: {
    Footer,
    NavBar
  },
  setup() {
    const store = useStore();
    store.dispatch('auth/getUser');

    const { onResult } = useQuery(GET_CURRENCIES);

    onResult(result => {
      const { data: { getCurrencies: currencies } } = result;
      store.commit('product/setCurrencies', currencies);
    })


    return {
      loadingUser: computed(() => store.state.auth.userLoading)
    }
  }
};
</script>
