<template>
  <Menu as="div" class="ml-3 relative">
    <div>
      <MenuButton class="bg-white rounded-full flex items-center hover:bg-gray-50 pr-2">
        <span class="sr-only">Open user menu</span>
        <img class="h-8 w-8 rounded-full" :src="media" alt="" />
        <span class="mr-1 ml-2 text-gray-600 text-sm">{{ name }}</span>
        <ChevronDownIcon class="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
      </MenuButton>
    </div>
    <transition
      enter-active-class="transition ease-out duration-200"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95">
      <MenuItems class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-20">
        <MenuItem v-slot="{ active }">
          <router-link to="/account" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">
            Settings
          </router-link>
        </MenuItem>
        <MenuItem v-slot="{ active }">
          <button
            class="w-full inline-flex"
            :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']"
            @click="logOut">
            Sign out
          </button>
        </MenuItem>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script>
import { ChevronDownIcon } from "@heroicons/vue/solid";
import { useLogout } from "@/use/logout";

export default {
  name: "ProfileButton",
  props: {
    media: {
      type: String,
      default: 'https://owayy.imgix.net/assets/dashboard/default-user.png?w=350&h=350&fit=max&auto=format,compress'
    },
    name: {
      type: String
    }
  },
  components: {
    ChevronDownIcon
  },
  setup() {
    const { logOut } = useLogout();

    return {
      logOut
    }
  }
};
</script>

<style scoped>

</style>
