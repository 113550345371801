<template>
  <TransitionRoot
    :show='isOpen'
    enter='transition-opacity duration-200'
    enter-from='opacity-0'
    enter-to='opacity-100'
    leave='transition-opacity duration-200'
    leave-from='opacity-100'
    leave-to='opacity-0'>
    <Dialog as='div' class='fixed inset-0 overflow-hidden z-30' @close='$emit("toggleMobileMenu")'>
      <div class='absolute inset-0 overflow-hidden'>
        <TransitionChild
          as="template"
          enter="ease-in-out duration-500"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in-out duration-500"
          leave-from="opacity-100"
          leave-to="opacity-0">
          <DialogOverlay class='absolute inset-0 bg-gray-700 bg-opacity-75 transition-opacity' />
        </TransitionChild>
        <div class='fixed inset-y-0 right-0 pl-10 max-w-full flex'>
          <TransitionChild
            as='template'
            enter='transform transition ease-in-out duration-200'
            enter-from='translate-x-full'
            enter-to='translate-x-0'
            leave='transform transition ease-in-out duration-200'
            leave-from='translate-x-0'
            leave-to='translate-x-full'>
            <div class='w-screen max-w-md'>
              <div class='h-full divide-y divide-gray-200 flex flex-col bg-universeBlue shadow-xl'>
                <div class='min-h-0 flex-1 flex flex-col py-6 overflow-y-scroll'>
                  <div class='px-4 sm:px-6'>
                    <div class='flex items-start justify-between'>
                      <DialogTitle class='text-lg font-medium text-gray-100'>
                      </DialogTitle>
                      <div class='ml-3 h-7 flex items-center'>
                        <button
                          type='button'
                          class='
                            bg-transparent
                            rounded-md
                            text-gray-100
                            hover:text-gray-300
                            focus:outline-none focus:ring-2 focus:ring-indigo-500
                          '
                          @click='$emit("toggleMobileMenu")'>
                          <span class='sr-only'>Close panel</span>
                          <XIcon class='h-6 w-6' aria-hidden='true' />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class='mt-6 relative flex-1 px-4 sm:px-6'>
                    <slot />
                  </div>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";

export default {
  name: 'MobileSlidein',
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true
    }
  },
  emits: ['toggleMobileMenu']
};
</script>

<style scoped></style>
