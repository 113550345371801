import gql from 'graphql-tag';

const GET_CURRENCIES = gql`
    query getCurrencies {
      getCurrencies {
        code
        id
        main
        symbol
      }
    }
`;


export {
  GET_CURRENCIES
};
