<template>
  <div class="navbar">
    <nav class="bg-white border-b">
      <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 desktop-menu">
        <div class="flex justify-between h-16">
          <div class="flex">
            <div class="flex-shrink-0 flex items-center relative">
              <router-link to="/">
                <img class="block lg:hidden h-8 w-auto" src="@/assets/icons/owayy/YY_logo_starrynight.svg" alt="Owayy Logo" />
                <img class="hidden lg:block h-8 w-auto" src="@/assets/icons/owayy/OWAYY_logo_starrynight.svg" alt="Owayy Logo" />
              </router-link>
            </div>
            <div class="hidden sm:ml-6 sm:flex sm:space-x-8">
              <!-- Current: "border-highlight-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" -->
              <router-link
                v-for="item in navigation"
                :key="item.name"
                :to="`${item.link}`"
                class="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm">
                {{ item.name }}
              </router-link>
            </div>
          </div>
          <div class="hidden sm:ml-6 sm:flex sm:items-center">
            <!-- Profile dropdown -->
            <ProfileButton :media="media"  :name="user.firstName" />
          </div>
          <div class="-mr-2 flex items-center sm:hidden">
            <!-- Mobile menu button -->
            <button class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-highlight-500" @click="toggleMobileMenu">
              <span class="sr-only">Open main menu</span>
              <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
              <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
      <!-- Mobile menu -->
      <MobileSlidein :is-open="isMobileMenuOpen" @toggleMobileMenu="toggleMobileMenu">
        <div class="pt-2 pb-8 space-y-1">
          <!-- Current: "bg-highlight-50 border-highlight-500 text-highlight-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" -->
          <router-link v-for="item in navigation" :key="item.name" :to="`${item.link}`" class="border-transparent text-gray-100 hover:bg-gray-800 hover:border-gray-300 block pl-3 pr-4 py-2 border-l-4 text-base font-medium">
            {{ item.name }}
          </router-link>
        </div>
        <div class="pt-4 pb-3 border-t border-gray-600">
          <div class="flex items-center px-4">
            <div class="flex-shrink-0">
              <img class="h-10 w-10 rounded-full" :src="media" alt="" />
            </div>
            <div class="ml-3">
              <div class="text-base font-medium text-gray-200">{{ user.firstName }}</div>
              <div class="text-sm text-gray-400">{{ user.email }}</div>
            </div>
          </div>
          <div class="mt-3 space-y-1">
            <router-link
              v-for="item in userNavigation"
              :key="item.name"
              :to="`${item.link}`"
              class="block px-4 py-2 text-base font-medium text-gray-200 hover:bg-gray-800">
              {{ item.name }}
            </router-link>
            <button class="block px-4 py-2 text-base font-medium text-gray-200 hover:bg-gray-800" @click="logOut()">Sign out</button>
          </div>
        </div>
      </MobileSlidein>
    </nav>
  </div>
</template>

<script>
import { ref, computed, watch } from "vue";
import { useStore } from 'vuex';
import { onBeforeRouteLeave, useRoute } from "vue-router";
import ProfileButton from "@/components/Profile/ProfileButton";
import MobileSlidein from "@/components/Navigation/MobileSlidein";
import { useLogout } from "@/use/logout";

const navigation = [
  {
    name: "Dashboard",
    link: "/"
  },
  {
    name: "Bookings",
    link: "/bookings?field=CREATED_AT&type=DESC"
  },
  {
    name: "Listings",
    link: "/listings"
  },
  {
    name: "Calendar",
    link: "/calendar"
  }
];
const userNavigation = [
  {
    name: "Settings",
    link: "/account"
  }
];

export default {
  name: "TheNavBar",
  components: {
    MobileSlidein,
    ProfileButton
  },
  setup() {
    const store = useStore();
    const isMobileMenuOpen = ref(false);
    const route = useRoute();

    const { logOut } = useLogout();

    const toggleMobileMenu = () => {
      isMobileMenuOpen.value = !isMobileMenuOpen.value;
    }

    const user = computed(() => store.state.auth.user);
    const media = computed(() => user.value.media?.media?.path ?
      `${process.env.VUE_APP_ASSETS_URL}/${user.value.media.media.path}` :
      'https://owayy.imgix.net/assets/dashboard/default-user.png?w=350&h=350&fit=max&auto=format,compress')

    watch(
      () => route.fullPath,
      () => {
        isMobileMenuOpen.value = false;
      }
    )


    onBeforeRouteLeave(() => {
      isMobileMenuOpen.value = false;
    })

    return {
      navigation,
      userNavigation,
      isMobileMenuOpen,
      toggleMobileMenu,
      user,
      logOut,
      media
    };
  }
};
</script>

<style scoped></style>
