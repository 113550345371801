import { useCookies } from "vue3-cookies";
import { useRouter } from "vue-router";
import { apolloClient } from "@/plugins/apollo/config";

export function useLogout() {
  const { cookies } = useCookies();
  const router = useRouter();

  const logOut = () => {
    cookies.remove('auth_session');
    apolloClient.cache.reset();
    router.push('/login');
  }

  return {
    logOut
  }
}

