<template>
  <div class="border-t py-2">
    <div class="max-w-5xl mx-auto px-4">
      <div class="text-center flex flex-col pt-2">
        <p class="text-sm text-gray-700">Happy hosting 💜</p>
        <div class="w-full sm:max-w-lg mx-auto flex justify-center mt-4 pt-2 border-t">
          <ul class="flex flex-col sm:flex-row gap-2 sm:gap-6">
            <a href="https://owayy.com" target="_blank" class="text-sm text-gray-600 hover:underline">Owayy.com</a>
            <a href="https://owayy.com/terms-of-service" target="_blank" class="text-sm text-gray-600 hover:underline">Terms</a>
            <a href="https://owayy.com/host-guidelines" target="_blank" class="text-sm text-gray-600 hover:underline">Host guidelines</a>
            <a href="mailto:host@owayy.com" target="_blank" class="text-sm text-gray-600 hover:underline">Contact us</a>
            <a href="https://owayy.slab.com/public/topics/host-community-eng-sukig532" target="_blank" class="text-sm text-gray-600 hover:underline">Community</a>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheFooter"
};
</script>

<style scoped>

</style>
